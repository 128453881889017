header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

header h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ==== CTA ==== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ==== Socials ==== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/* ==== Me ==== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.me img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* ==== Scroll ==== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ==== Media Queries ==== */

@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
}

@media screen and (max-width: 740px) {
    header{
        height: 90vh;
        margin-bottom: 0;
    }

    .header__socials a {
        font-size: 2rem;
        width: 20%;
    }

    .header__socials{
        display: flex; /* Ensure the socials are visible */
        flex-direction: row; /* Optional: Align socials in a row */
        justify-content: center; /* Center them horizontally */
        gap: 1rem; /* Add spacing between items */
        position: static; /* Optional: Reset positioning */
        padding-top: 20px;
    }

    .header__socials::after {
        display: none;
    }

    .scroll__down{
        display: none;
    }

    .me {
        display: none;
        margin: 0;
    }
}